<template>
  <div id="StockScreen">
    <v-container>
      <v-row>
        <v-col class="col-12 col-lg-9">
          <stock-list></stock-list>
        </v-col>
        <v-col class="col-12 col-lg-3">
          <v-card>
            <v-app-bar dense color="transparent">
              <v-toolbar-title>
                <h5>Masukkan Pencarian</h5>
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-text-field
                  dense
                  outlined
                  label="Nama Barang"
                  v-model="productName"
                  clearable
                ></v-text-field>
                <v-select
                  dense
                  outlined
                  v-model="kategoriSelect"
                  :items="categoryData"
                  item-text="category_name"
                  item-value="id"
                  menu-props="auto"
                  label="Kategori"
                  clearable
                ></v-select>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                block
                color="primary"
                @click="getStockBySearch"
                :loading="loading"
                :disabled="loadingStatus"
                >Cari</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import StockList from "@/components/Widget/mitra/StockList";

export default {
  components: {
    StockList
  },

  data() {
    return {
      loading: false,
      valid: true,
      productName: "",
      kategoriSelect: ""
    };
  },

  mounted() {
    this.$store.dispatch("stock/getAll");
  },

  computed: {
    loadingStatus() {
      return this.$store.getters.getLoading;
    },
    categoryData() {
      return this.$store.getters["stock/getCategoryList"];
    }
  },

  methods: {
    getStockBySearch() {
      this.loading = true;

      let params = {
        product_name: this.productName,
        category_id: this.kategoriSelect === null ? "" : this.kategoriSelect
      };

      this.$store
        .dispatch("stock/getBySearch", params)
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
