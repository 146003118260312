<template>
  <v-card>
    <v-app-bar dense color="transparent">
      <v-toolbar-title>
        <h5>Stok Barang</h5>
      </v-toolbar-title>
      <v-spacer />
      <v-icon @click="refreshData">mdi-refresh</v-icon>
    </v-app-bar>

    <v-card-text class="pa-0">
      <template>
        <v-data-table
          :headers="headers"
          :items="stocksData"
          :loading="loadingStatus"
          loading-text="Sedang Menyiapkan Data..."
          mobile-breakpoint="0"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.product_name }}</td>
              <td>{{ props.item.category_name }}</td>
              <td>
                <v-chip :color="getColorByStock(props.item.stock)">
                  {{ props.item.stock }}
                </v-chip>
              </td>
              <td>{{ formatRupiah(props.item.selling_price) }}</td>
              <td>{{ props.item.unit }}</td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";

export default {
  mixins: [globalMixin],

  data() {
    return {
      headers: [
        { text: "Nama Produk", sortable: false },
        { text: "Kategori", sortable: false },
        { text: "Jumlah Produk", value: "stock" },
        { text: "Harga Satuan", sortable: false },
        { text: "Satuan", sortable: false }
      ],
      stockColors: {
        less: "error",
        more: "success"
      }
    };
  },

  computed: {
    loadingStatus() {
      return this.$store.getters.getLoading;
    },
    stocksData() {
      return this.$store.getters["stock/getStocks"];
    }
  },

  methods: {
    getColorByStock(stock) {
      if (stock < 100) {
        return this.stockColors.less;
      }
      if (stock >= 100) {
        return this.stockColors.more;
      }
    },
    refreshData() {
      this.$store.dispatch("stock/getAll");
    }
  }
};
</script>
